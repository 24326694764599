@charset "UTF-8";
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  background: transparent;
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

body {
  line-height: 1;
}

li {
  list-style: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

a {
  background: transparent;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: 700;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

.flag-forum-report-node,
.flag-forum-report-comment, .forum-post-links ul.links, .news__more-link-wrapper, .mobile-menu__wrapper .navigation__menu-tree, .header__mobile-lindholmen-logo, .header__container, .admin-tabs, .wrapper {
  *zoom: 1;
}
.flag-forum-report-node:before,
.flag-forum-report-comment:before, .forum-post-links ul.links:before, .news__more-link-wrapper:before, .mobile-menu__wrapper .navigation__menu-tree:before, .header__mobile-lindholmen-logo:before, .header__container:before, .admin-tabs:before, .wrapper:before, .flag-forum-report-node:after,
.flag-forum-report-comment:after, .forum-post-links ul.links:after, .news__more-link-wrapper:after, .mobile-menu__wrapper .navigation__menu-tree:after, .header__mobile-lindholmen-logo:after, .header__container:after, .admin-tabs:after, .wrapper:after {
  content: " ";
  display: table;
}
.flag-forum-report-node:after,
.flag-forum-report-comment:after, .forum-post-links ul.links:after, .news__more-link-wrapper:after, .mobile-menu__wrapper .navigation__menu-tree:after, .header__mobile-lindholmen-logo:after, .header__container:after, .admin-tabs:after, .wrapper:after {
  clear: both;
}

html,
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  box-sizing: border-box;
  font-size: 80%;
  min-height: 100%;
  position: relative;
  text-size-adjust: 100%;
  font-size: 90%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  font-family: "apercu-regular", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5 {
  font-family: "apercu-bold";
  font-weight: normal;
  color: #333;
}

p {
  font-family: "apercu-regular";
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}

strong, b {
  font-family: "apercu-bold";
  font-weight: normal;
}

strong,
b {
  font-family: "apercu-bold";
  font-weight: normal;
}
strong em,
strong i,
b em,
b i {
  font-family: "apercu-bold-italic";
  font-style: normal;
}

em,
i {
  font-family: "apercu-italic";
  font-style: normal;
}
em strong,
em b,
i strong,
i b {
  font-family: "apercu-bold-italic";
  font-weight: normal;
}

.hidden {
  display: none;
}

.wrapper {
  margin: 0 4.6875%;
  max-width: 1200px;
}

.wrapper--header {
  height: 100%;
  position: relative;
}

.wrapper--content {
  margin-top: 35px;
  padding-bottom: 15px;
}

.wrapper--footer {
  height: 100%;
}

.main-content {
  float: left;
  margin-right: -100%;
  width: 100%;
}
.sidebar {
  clear: left;
  float: left;
  margin-right: -100%;
  width: 100%;
}
.sidebar .block {
  float: left;
  width: 100%;
}

.sidebar__section {
  border-bottom: 1px solid #ebebeb;
  float: left;
  margin-bottom: 20px;
  padding-bottom: 30px;
  width: 100%;
}

.sidebar__section-header {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 10px;
}

.footer__region--2 {
  float: left;
  margin-left: 34.2936%;
  margin-right: -100%;
  width: 31.3639%;
}

.footer__region--bottom {
  clear: left;
  float: left;
  width: 100%;
}

.limited-content p {
  max-width: 790px;
}

/*
 * Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2017 Colophon Foundry
 *
 * Licenced to Andr&eacute; Aznar Kodamera
 */
/* * * * * * * * * * * * * * * * * * * *
*  Apercu font
* * * * * * * * * * * * * * * * * * * */
@font-face {
  font-family: "apercu-bold-italic";
  src: url("../../fonts/apercu/apercu-bold-italic.eot");
  src: url("../../fonts/apercu/apercu-bold-italic.eot?#iefix") format("embedded-opentype"), url("../../fonts/apercu/apercu-bold-italic.woff") format("woff"), url("../../fonts/apercu/apercu-bold-italic.woff2") format("woff2"), url("../../fonts/apercu/apercu-bold-italic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "apercu-italic";
  src: url("../../fonts/apercu/apercu-italic.eot");
  src: url("../../fonts/apercu/apercu-italic.eot?#iefix") format("embedded-opentype"), url("../../fonts/apercu/apercu-italic.woff") format("woff"), url("../../fonts/apercu/apercu-italic.woff2") format("woff2"), url("../../fonts/apercu/apercu-italic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Apercu";
  src: url("Apercu-Medium.woff2") format("woff2"), url("Apercu-Medium.woff") format("woff"), url("Apercu-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "apercu-medium";
  src: url("../../fonts/apercu/Apercu-Medium.eot");
  src: url("../../fonts/apercu/Apercu-Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/apercu/Apercu-Medium.woff2") format("woff2"), url("../../fonts/apercu/Apercu-Medium.woff") format("woff"), url("../../fonts/apercu/Apercu-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "apercu-regular";
  src: url("../../fonts/apercu/apercu-regular.eot");
  src: url("../../fonts/apercu/apercu-regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/apercu/apercu-regular.woff") format("woff"), url("../../fonts/apercu/apercu-regular.woff2") format("woff2"), url("../../fonts/apercu/apercu-regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "apercu-bold";
  src: url("../../fonts/apercu/apercu-bold.eot");
  src: url("../../fonts/apercu/apercu-bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/apercu/apercu-bold.woff") format("woff"), url("../../fonts/apercu/apercu-bold.woff2") format("woff2"), url("../../fonts/apercu/apercu-bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* * * * * * * * * * * * * * * * * * * *
*  Tiempos font
* * * * * * * * * * * * * * * * * * * */
@font-face {
  font-family: "tiempos-bold-italic";
  src: url("../../fonts/tiempos/TiemposTextWeb-BoldItalic.eot");
  src: url("../../fonts/tiempos/TiemposTextWeb-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../../fonts/tiempos/TiemposTextWeb-BoldItalic.woff") format("woff"), url("../../fonts/tiempos/TiemposTextWeb-BoldItalic.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "tiempos-bold";
  src: url("../../fonts/tiempos/TiemposTextWeb-Bold.eot");
  src: url("../../fonts/tiempos/TiemposTextWeb-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/tiempos/TiemposTextWeb-Bold.woff") format("woff"), url("../../fonts/tiempos/TiemposTextWeb-Bold.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "tiempos-italic";
  src: url("../../fonts/tiempos/TiemposTextWeb-RegularItalic.eot");
  src: url("../../fonts/tiempos/TiemposTextWeb-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../../fonts/tiempos/TiemposTextWeb-RegularItalic.woff") format("woff"), url("../../fonts/tiempos/TiemposTextWeb-RegularItalic.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "tiempos-regular";
  src: url("../../fonts/tiempos/TiemposTextWeb-Regular.eot");
  src: url("../../fonts/tiempos/TiemposTextWeb-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/tiempos/TiemposTextWeb-Regular.woff") format("woff"), url("../../fonts/tiempos/TiemposTextWeb-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
.skip-to-content__link {
  background: #333;
  clip: rect(1px, 1px, 1px, 1px);
  color: #fff;
  display: block;
  height: 1px;
  overflow: hidden;
  padding: 5px;
  position: absolute;
  text-decoration: none;
}
.skip-to-content__link:focus {
  clip: auto;
  height: auto;
  outline: 0;
  overflow: visible;
  position: static;
}

.admin-tabs {
  margin: 12px 0;
}
.admin-tabs .tabs {
  background: #f8f8f8;
  border: 1px solid #ededed;
  border-radius: 3px;
}

.admin-tabs__list-item {
  border-bottom: 1px solid #ededed;
}
.admin-tabs__list-item:first-child .admin-tabs__link {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.admin-tabs__list-item:last-child {
  border-bottom: 0;
}
.admin-tabs__list-item:last-child .admin-tabs__link {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.admin-tabs__link {
  color: #333;
  display: block;
  padding: 5px 10px;
  text-decoration: none;
}
.admin-tabs__link:hover {
  background: #f0f0f0;
  color: black;
}
.admin-tabs__link:focus {
  background: #333;
  color: #fff;
  outline: 0;
}

.js .language-switch--mobile {
  display: none;
}
.mobile-nav--is-open .language-switch--mobile {
  display: block;
}
.language-switch--mobile .language-switch__link {
  border: 1px solid #fff;
  color: #fff;
  display: block;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
.language-switch--mobile .language-switch__link:hover {
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.language-switch--desktop {
  display: none;
}
.language-switch--desktop .language-switch__link {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.2;
  color: #fff;
  margin-left: 20px;
  text-decoration: none;
  text-transform: uppercase;
}
.language-switch--desktop .language-switch__link:hover {
  text-decoration: underline;
}

.header {
  background-color: #666;
  position: relative;
}

.header__bg {
  background-position: center;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.header__gradient {
  height: 100%;
  left: 0;
  opacity: 0.9;
  position: absolute;
  top: 0;
  width: 100%;
}

.header__container {
  margin-bottom: 20px;
  padding: 12px 4.6875%;
  position: relative;
}

.header__inner-wrapper {
  margin: 0 auto;
  max-width: 1200px;
}

.header__mobile-lindholmen-logo {
  background: #fff;
  max-width: 1200px;
  padding: 0 4.6875%;
}

.header__lindholmen-link--mobile {
  color: #000;
  display: block;
  padding: 4px 0;
  text-decoration: none;
}

.header__desktop-lindholmen-logo {
  display: none;
}

.header__site-name {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.2;
  float: left;
  font-weight: 300;
}

.header__site-link {
  color: #fff;
  text-decoration: none;
}
.header__site-link img {
  display: block;
  max-width: 200px;
  max-height: 70px;
  max-width: 600px;
}

.header__logo {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1;
  float: left;
}

.mobile-menu__wrapper {
  max-height: 0;
  overflow: hidden;
  position: relative;
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  width: 100%;
}
.mobile-nav--is-open .mobile-menu__wrapper {
  margin: 0 0 40px;
  max-height: 800px;
  overflow: visible;
}
.no-js .mobile-menu__wrapper {
  max-height: 800px;
  overflow: visible;
}
.mobile-menu__wrapper .navigation__menu-tree {
  opacity: 0;
}
.mobile-nav--is-open .mobile-menu__wrapper .navigation__menu-tree {
  animation: mainNavfadeIn 0.5s;
  opacity: 1;
}
.mobile-menu__wrapper .navigation__menu-tree .leaf a,
.mobile-menu__wrapper .navigation__menu-tree .expanded a,
.mobile-menu__wrapper .navigation__menu-tree .collapsed a {
  color: #fff;
  display: block;
  padding: 10px 13px 9px;
  text-decoration: none;
}
.mobile-menu__wrapper .navigation__menu-tree .leaf a:hover,
.mobile-menu__wrapper .navigation__menu-tree .expanded a:hover,
.mobile-menu__wrapper .navigation__menu-tree .collapsed a:hover {
  background: rgba(0, 0, 0, 0.1);
}
.mobile-menu__wrapper .navigation__menu-tree .leaf a:focus,
.mobile-menu__wrapper .navigation__menu-tree .expanded a:focus,
.mobile-menu__wrapper .navigation__menu-tree .collapsed a:focus {
  background: rgba(0, 0, 0, 0.1);
  outline: 0;
}
.mobile-menu__wrapper .navigation__menu-tree .leaf a.active, .mobile-menu__wrapper .navigation__menu-tree .leaf a.active-trail,
.mobile-menu__wrapper .navigation__menu-tree .expanded a.active,
.mobile-menu__wrapper .navigation__menu-tree .expanded a.active-trail,
.mobile-menu__wrapper .navigation__menu-tree .collapsed a.active,
.mobile-menu__wrapper .navigation__menu-tree .collapsed a.active-trail {
  background: rgba(0, 0, 0, 0.2);
}
.mobile-menu__wrapper .navigation__menu-tree .leaf .navigation__show-hidden-links,
.mobile-menu__wrapper .navigation__menu-tree .expanded .navigation__show-hidden-links,
.mobile-menu__wrapper .navigation__menu-tree .collapsed .navigation__show-hidden-links {
  margin-right: 0;
}
.mobile-menu__wrapper .navigation__menu-tree .leaf .leaf a,
.mobile-menu__wrapper .navigation__menu-tree .leaf .expanded a,
.mobile-menu__wrapper .navigation__menu-tree .leaf .collapsed a,
.mobile-menu__wrapper .navigation__menu-tree .expanded .leaf a,
.mobile-menu__wrapper .navigation__menu-tree .expanded .expanded a,
.mobile-menu__wrapper .navigation__menu-tree .expanded .collapsed a,
.mobile-menu__wrapper .navigation__menu-tree .collapsed .leaf a,
.mobile-menu__wrapper .navigation__menu-tree .collapsed .expanded a,
.mobile-menu__wrapper .navigation__menu-tree .collapsed .collapsed a {
  padding-left: 26px;
}
.mobile-menu__wrapper .navigation__menu-tree .expanded {
  background: rgba(0, 0, 0, 0.1);
}
.mobile-menu__wrapper .leaf .navigation__hidden-menu-links {
  background: #fff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  position: absolute;
  right: 0;
  top: 47px;
  white-space: nowrap;
  z-index: 10000;
}
.mobile-menu__wrapper .leaf .navigation__hidden-menu-links .leaf,
.mobile-menu__wrapper .leaf .navigation__hidden-menu-links .expanded,
.mobile-menu__wrapper .leaf .navigation__hidden-menu-links .collapsed {
  display: block;
  float: none;
  opacity: 1;
  width: 100%;
  z-index: 1000;
}
.mobile-menu__wrapper .leaf .navigation__hidden-menu-links .leaf a,
.mobile-menu__wrapper .leaf .navigation__hidden-menu-links .expanded a,
.mobile-menu__wrapper .leaf .navigation__hidden-menu-links .collapsed a {
  color: #666;
  margin-right: 0;
  padding-right: 26px;
}
.mobile-menu__wrapper .leaf .navigation__hidden-menu-links > .expanded {
  display: block;
  width: 100%;
}
.mobile-menu__wrapper .leaf .navigation__hidden-menu-links > .expanded a {
  display: block;
  margin-right: 0;
  padding-right: 26px;
}
.mobile-menu__wrapper .leaf .navigation__hidden-menu-links > .expanded .navigation__menu-tree {
  display: none;
}
.mobile-menu__wrapper .leaf .navigation__hidden-menu-links > .collapsed {
  display: block;
}
.mobile-menu__wrapper .leaf .navigation__hidden-menu-links > .collapsed .collapsed {
  display: none;
}

@keyframes mainNavfadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.navigation__mobile-toggle {
  margin-top: 5px;
  display: none;
}

.navigation__mobile-button {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  float: right;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  padding: 7px 8px;
  position: relative;
  text-transform: uppercase;
}
.navigation__mobile-button:active {
  background: rgba(0, 0, 0, 0.1);
}

.navigation__mobile-toggle-label {
  float: right;
  line-height: 1;
  margin-right: 24px;
}

.navigation__mobile-toggle-label--on {
  display: none;
}
.mobile-nav--is-open .navigation__mobile-toggle-label--on {
  display: block;
}

.mobile-nav--is-open .navigation__mobile-toggle-label--off {
  display: none;
}

.navigation__mobile-hamburger {
  background: #fff;
  border: 0;
  display: inline-block;
  height: 2px;
  margin-bottom: 5px;
  margin-left: 4px;
  position: absolute;
  right: 9px;
  top: 14px;
  transition: 0.1s;
  width: 16px;
}
.mobile-nav--is-open .navigation__mobile-hamburger {
  background: transparent;
}
.navigation__mobile-hamburger::before, .navigation__mobile-hamburger::after {
  background: #fff;
  content: "";
  display: inline-block;
  height: 2px;
  left: 0;
  position: absolute;
  transition: 0.1s;
  width: 16px;
}
.navigation__mobile-hamburger::before {
  top: 5px;
}
.mobile-nav--is-open .navigation__mobile-hamburger::before {
  top: 0;
  transform: rotate3d(0, 0, 1, 45deg);
  transform-origin: 50% 50%;
}
.navigation__mobile-hamburger::after {
  top: -5px;
}
.mobile-nav--is-open .navigation__mobile-hamburger::after {
  top: 0;
  transform: rotate3d(0, 0, 1, -45deg);
  transform-origin: 50% 50%;
}

.navigation__arrow {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 13px solid #fff;
  display: inline-block;
  height: 0;
  margin-left: 5px;
  width: 0;
}

.js-navigation__show-hidden-links {
  position: relative;
}

.js-navigation__hidden-menu-links--closed {
  display: none;
}

.js-navigation__hidden-menu-links--open {
  display: block;
}

.navigation__show-hidden-links {
  position: relative;
}

/**
  * @name Site-Navigation--Secondary
  * @description Style for the site-navigation--secondary class
  */
.site-navigation--secondary {
  position: absolute;
  right: 60px;
  top: 50%;
  z-index: 1;
  margin-top: -18px;
}

@media only screen and (min-width: 836px) {
  .site-navigation--secondary {
    right: 20px;
    top: -5px;
    margin-top: 0;
  }
}
.site-navigation--secondary li {
  margin-left: 10px;
}

.site-navigation--secondary li.first {
  margin-left: 0;
}

@media only screen and (max-width: 835px) {
  .site-navigation--secondary li {
    float: right;
  }
}
.site-navigation--secondary li > a {
  color: #bbbbbb;
}

.site-intro__container {
  float: left;
  width: 100%;
}
.site-intro__container h2 {
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 1.3;
  color: #fff;
  font-weight: 400;
  margin: 0 0 10px;
  text-align: left;
}
.site-intro__container p {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.3;
  color: #fff;
  display: inline-block;
  max-width: 790px;
  padding: 0 6px;
  text-align: left;
}

.secondary-navigation {
  border-bottom: 1px solid #ebebeb;
  float: left;
  margin-bottom: 20px;
  padding-bottom: 30px;
  width: 100%;
}
.secondary-navigation .block__title a {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.3;
  background: transparent;
  border-bottom: 1px solid #ebebeb;
  color: #000;
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 10px 13px 9px;
  text-decoration: none;
}
.secondary-navigation .block__title a:hover {
  text-decoration: underline;
}
.secondary-navigation .leaf a,
.secondary-navigation .collapsed a,
.secondary-navigation .expanded a {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.3;
  color: #333;
  display: block;
  padding: 10px 13px;
  text-decoration: none;
}
.secondary-navigation .leaf a:hover,
.secondary-navigation .collapsed a:hover,
.secondary-navigation .expanded a:hover {
  background: #666;
  color: #fff;
}
.secondary-navigation .leaf a.active,
.secondary-navigation .collapsed a.active,
.secondary-navigation .expanded a.active {
  background: #666;
  font-weight: 700;
}
.secondary-navigation .leaf a.active-trail,
.secondary-navigation .collapsed a.active-trail,
.secondary-navigation .expanded a.active-trail {
  background: #666;
  color: #fff;
}
.secondary-navigation .leaf a.active-trail:hover,
.secondary-navigation .collapsed a.active-trail:hover,
.secondary-navigation .expanded a.active-trail:hover {
  background: #666;
  color: #fff;
}
.secondary-navigation .leaf .leaf,
.secondary-navigation .collapsed .leaf,
.secondary-navigation .expanded .leaf {
  background: #e9f7f7;
  padding-bottom: 10px;
}
.secondary-navigation .leaf .leaf,
.secondary-navigation .leaf .collapsed,
.secondary-navigation .leaf .expanded,
.secondary-navigation .collapsed .leaf,
.secondary-navigation .collapsed .collapsed,
.secondary-navigation .collapsed .expanded,
.secondary-navigation .expanded .leaf,
.secondary-navigation .expanded .collapsed,
.secondary-navigation .expanded .expanded {
  padding-bottom: 0;
}
.secondary-navigation .leaf .leaf a,
.secondary-navigation .leaf .collapsed a,
.secondary-navigation .leaf .expanded a,
.secondary-navigation .collapsed .leaf a,
.secondary-navigation .collapsed .collapsed a,
.secondary-navigation .collapsed .expanded a,
.secondary-navigation .expanded .leaf a,
.secondary-navigation .expanded .collapsed a,
.secondary-navigation .expanded .expanded a {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.3;
  padding: 10px 26px;
}
.secondary-navigation .leaf .leaf a:hover,
.secondary-navigation .leaf .collapsed a:hover,
.secondary-navigation .leaf .expanded a:hover,
.secondary-navigation .collapsed .leaf a:hover,
.secondary-navigation .collapsed .collapsed a:hover,
.secondary-navigation .collapsed .expanded a:hover,
.secondary-navigation .expanded .leaf a:hover,
.secondary-navigation .expanded .collapsed a:hover,
.secondary-navigation .expanded .expanded a:hover {
  background: #666;
  color: #fff;
}
.secondary-navigation .leaf .leaf a.active,
.secondary-navigation .leaf .collapsed a.active,
.secondary-navigation .leaf .expanded a.active,
.secondary-navigation .collapsed .leaf a.active,
.secondary-navigation .collapsed .collapsed a.active,
.secondary-navigation .collapsed .expanded a.active,
.secondary-navigation .expanded .leaf a.active,
.secondary-navigation .expanded .collapsed a.active,
.secondary-navigation .expanded .expanded a.active {
  background: transparent;
  color: #333;
  font-weight: 700;
}
.secondary-navigation .leaf .leaf a.active::before,
.secondary-navigation .leaf .collapsed a.active::before,
.secondary-navigation .leaf .expanded a.active::before,
.secondary-navigation .collapsed .leaf a.active::before,
.secondary-navigation .collapsed .collapsed a.active::before,
.secondary-navigation .collapsed .expanded a.active::before,
.secondary-navigation .expanded .leaf a.active::before,
.secondary-navigation .expanded .collapsed a.active::before,
.secondary-navigation .expanded .expanded a.active::before {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1;
  content: "\e60d";
  font-family: "Lindholmen icons";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  left: 0;
  padding-right: 8px;
  position: relative;
  text-transform: none;
  top: 1px;
}

.news--view {
  float: left;
}

.news__grid-1 {
  margin-right: -100%;
  width: 100%;
}

.news__grid-2 {
  margin-right: -100%;
  width: 100%;
}

.news__grid-first {
  clear: none;
}

.news__list-item {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 20px;
  padding-bottom: 30px;
}

.main-content a.news__item:hover {
  text-decoration: none;
}
.main-content a.news__item:hover .news__title {
  text-decoration: underline;
}

.news__title {
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 1.2;
  font-family: "apercu-regular";
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.news__item--teaser .news__title {
  color: #333;
}
.news__item--featured .news__title {
  color: #666;
}

.news__date,
.news__date-created {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.2;
  color: #999;
  display: block;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.news__image-wrapper {
  position: relative;
}

.news__label {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.2;
  background: #666;
  color: #fff;
  display: inline;
  padding: 3px 6px;
  text-transform: uppercase;
}

.news__label--featured,
.news__label--super {
  display: block;
  left: 0;
  position: absolute;
  top: 10px;
  z-index: 10;
}

.post__meta--teaser {
  display: inline;
}

.post__teaser {
  color: #333;
  font-family: "tiempos-regular", "Georgia", serif;
  line-height: 1.5;
}

.news__label--super {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.2;
  top: 40px;
}

.news__label-wrapper {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.2;
  color: #999;
  display: inline;
}

.news__label--teaser {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.2;
  background: #666;
  border-radius: 2px;
  color: #fff;
  display: inline;
  margin-left: 2px;
  padding: 4px 5px 2px 6px;
}

.news__super-list {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 20px;
  padding-bottom: 30px;
}

.news__super-list-item {
  background: #262626;
  padding: 20px 20px 40px;
}
.news__super-list-item .news__super-link {
  color: #fff;
}

.blog__super-list-item {
  padding: 20px 20px 0;
}

.news__super-title {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 5px;
}

.news__super-teaser {
  color: #aaa;
}
.blog__super-list-item .news__super-teaser {
  color: #333;
}

.news__video--super {
  margin-bottom: 10px;
  position: relative;
}

.news__more-link-wrapper {
  background: #666;
  float: left;
  margin-bottom: 60px;
  width: 100%;
}

.main-content .news__more-link {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.2;
  color: #fff;
  float: right;
  padding: 11px 16px 8px;
}
.news__more-link::before {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2;
  content: "\e60d";
  float: right;
  font-family: "Lindholmen icons";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  left: 6px;
  position: relative;
  text-transform: none;
  top: 3px;
}

.section {
  background: #f7f7f7;
  border: 1px solid #e4e4e4;
  clear: both;
  float: left;
  margin-bottom: 1.611111111em;
  padding: 0 20px;
  width: 100%;
}

.section__title {
  color: #7f8389;
  font-weight: normal;
  margin-bottom: 30px;
  margin-top: 22px;
  text-align: center;
  text-transform: uppercase;
}

.section--view {
  float: left;
  width: 100%;
}

.partners__list-item {
  padding: 2em 0;
}

.partners__list-item--sidebar {
  float: left;
  padding: 1em 0;
  width: 45.508982%;
}
.partners__list-item--sidebar.odd {
  margin-right: 8.9820359%;
}

.partners__logo {
  display: block;
  width: auto;
}

.contacts__list-item {
  margin-bottom: 40px;
  width: 100%;
}

.contact__image {
  max-width: 170px;
}

.contact__role {
  color: #777;
}

.contact__role-description {
  font-size: 1rem;
  line-height: 1.3rem;
  margin-bottom: 1rem;
  max-width: 240px;
  width: 100%;
}

.contact__name {
  font-weight: 700;
}

.contact__phone,
.contact__email {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contact__phone a,
.contact__email a {
  color: #666;
  text-decoration: none;
}
.contact__phone a:hover,
.contact__email a:hover {
  text-decoration: underline;
}

.contact__phone {
  margin-top: 8px;
}

.referenced__list-title {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 20px;
}

.referenced-content--view {
  float: left;
  margin-bottom: 20px;
}

.referenced-content__list-item {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 30px;
  padding-bottom: 10px;
}

.referenced-content__list-item--odd {
  margin-right: -100%;
  width: 100%;
}

.referenced-content__list-item--even {
  margin-right: -100%;
  width: 100%;
}

.referenced-content__list-item--first {
  clear: none;
}

.referenced-content__wrapper .referenced-content__link {
  display: block;
}
.referenced-content__wrapper .referenced-content__link:hover {
  text-decoration: none;
}
.referenced-content__wrapper .referenced-content__link:hover .referenced-content__title {
  text-decoration: underline;
}

.referenced-content__title {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.2;
  margin-bottom: 8px;
}

.referenced-content__preamble {
  color: #333;
  text-decoration: none;
}
.referenced-content__preamble:hover {
  text-decoration: none;
}

.resources__main-wrapper {
  margin-top: 40px;
  overflow: auto;
  clear: left;
}
.resources__main-wrapper.resources__main-wrapper--contacts {
  overflow: initial;
}

.resources__main-title {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.2;
  float: left;
  margin-bottom: 30px;
  width: 100%;
}

.resources__preamble {
  float: left;
  width: 100%;
}

.resources--view {
  float: left;
  margin-bottom: 20px;
  width: 100%;
}

.resource__list-item {
  border-bottom: 1px solid #ebebeb;
  margin: 20px 0;
  padding: 20px 0;
}
.resource__list-item.last {
  border-bottom: 0;
}

.resources__group {
  border-top: 1px solid #ebebeb;
}
.resources__group:last-child {
  border-bottom: 1px solid #ebebeb;
}

.resources__group-title {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #333;
  padding: 20px 0 20px 10px;
  position: relative;
  user-select: none;
}
.resources__group-title::after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #333;
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.resources__group-title:hover {
  cursor: pointer;
}
.resources__group-title:focus {
  text-decoration: underline;
}

.resources__group-title--open::after {
  border-bottom: 10px solid #333;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 0;
}

.resources__list {
  margin: 0 10px;
}
.js .resources__group .resources__list {
  display: none;
}

.resources__contacts {
  display: flex;
  flex-flow: row wrap;
  clear: left;
}
.resources__contacts .node-contact-person {
  padding-right: 20px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 260px;
}

.resource__item {
  margin-bottom: 30px;
}
.resources__list-item:last-child .resource__item {
  margin-bottom: 10px;
}

.resource__item--author {
  box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 5.1764706%;
}
.main-content .resource__item--author a:hover {
  text-decoration: none;
}

.resource__title {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.2;
}

.resource__title--author {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.2;
  color: #333;
  font-weight: bold;
  margin-bottom: 15px;
}

.resource__link-separator,
.resource__external-link {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.2;
}

.resource__link-separator {
  color: #aaa;
  font-weight: 400;
}

.resource__external-link {
  font-weight: 400;
  text-overflow: ellipsis;
}

.resource__phone-number {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.2;
  display: block;
  margin-bottom: 4px;
}
.resource__phone-number::before {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.2;
  content: "󦄑";
  float: left;
  font-family: "Lindholmen icons";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  margin-right: 8px;
  position: relative;
  text-transform: none;
  top: 2px;
}

.resource__address {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.2;
  color: #999;
  float: left;
  margin: 10px 0;
}
.resource__address::before {
  content: "\e608";
  display: block;
  float: left;
  font-family: "Lindholmen icons";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  margin-right: 8px;
  position: relative;
  text-transform: none;
}
.resource__address p {
  float: left;
  margin-bottom: 0;
}

.resource__image-wrapper {
  float: left;
  position: relative;
  margin-left: 0;
  margin-right: 0;
  padding-right: 0;
  width: 100%;
}

.resource__image-wrapper--author {
  float: none;
  margin: 0;
  width: 100%;
}

.resource__image-link {
  display: block;
  text-align: center;
}
.resource__image-link:hover .resource__image-overlay-wrapper, .resource__image-link:hover .resource__image-overlay {
  display: block;
}

.resource__image-overlay-wrapper {
  background: #333;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  position: absolute;
  right: 0;
  top: 0;
}
.js .resource__image-overlay-wrapper {
  display: none;
}

.resource__image-overlay {
  color: #fff;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.js .resource__image-overlay {
  display: none;
}

.resource__image {
  display: block;
}

.resource__data-wrapper {
  border-bottom: 1px dotted #e3e3e3;
  float: left;
  padding-bottom: 40px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1rem;
  width: 100%;
}
.resource__data-wrapper .spamspan {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.2;
  clear: both;
  display: block;
  margin: 10px 0 4px;
}
.resource__data-wrapper .spamspan::before {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.2;
  content: "\e612";
  float: left;
  font-family: "Lindholmen icons";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  margin-right: 6px;
  position: relative;
  text-transform: none;
  top: 3px;
}
.resource__data-wrapper .resource__link--button {
  background: #666;
  color: #fff;
  display: inline-block;
  margin-right: 5px;
  padding: 6px 19px;
}
.resource__data-wrapper .resource__link--button:hover {
  background: #545454;
  text-decoration: none;
}
.resources__list-item:last-child .resource__data-wrapper {
  border-bottom: 0;
}

.resource__data-wrapper--author {
  border: 0;
  float: none;
  margin: 0;
  padding: 35px;
  width: 100%;
}

.resource__description {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.3;
  color: #999;
}
.resource__description p {
  margin-bottom: 0;
}

.resource__description--author {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.88;
  color: #333;
}

.resource__description--gallery-image {
  color: #333;
}

.resource__image-credit {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.3;
  background: url("../../images/resource-camera.png") no-repeat 0 50%;
  background-size: 14px 10px;
  color: #999;
  margin-top: 6px;
  padding-left: 18px;
}

.resource__publish-date {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.3;
  color: #999;
}

.resource__file-meta {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3;
  color: #999;
}

.breadcrumbs__wrapper {
  bottom: -18px;
  position: absolute;
  width: 100%;
}
.breadcrumbs__wrapper::after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 70%);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
}

.breadcrumbs {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.4;
  background: #fff;
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 10px 13px;
  text-transform: uppercase;
  white-space: nowrap;
}
.breadcrumbs a {
  border-radius: 3px;
  color: #666;
  padding: 5px 2px 5px 5px;
  text-decoration: none;
}
.breadcrumbs a:hover {
  text-decoration: underline;
}
.breadcrumbs a:focus {
  background: #666;
  color: #fff;
  outline: 0;
}

.breadcrumbs__separator {
  color: #b4b4b4;
}

.breadcrumbs__current-page {
  padding-left: 2px;
}

.content__wrap--with-sidebar {
  float: left;
  margin-right: -100%;
  width: 100%;
}

.content__sidebar {
  clear: left;
  float: left;
  margin-right: -100%;
  width: 100%;
}

.content__title {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.2;
  color: #666;
  font-weight: 300;
  margin-bottom: 5px;
}

.content__subtitle--h2 {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.3;
  color: #333;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.content__subtitle--h3 {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.3;
  color: #333;
  margin-bottom: 0.5rem;
}

.content__subtitle--h4 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.3;
  color: #667177;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.content__code {
  background: #fafafa;
  border: 1px solid #f0f2f3;
  border-radius: 3px;
  color: #292e31;
  font-weight: 600;
  padding: 2px 4px;
}

.content__cta {
  background: #fafafa;
  border: 1px solid #e8eaeb;
  border-radius: 5px;
  color: #6f7c82;
  margin: 35px 0 15px;
  padding: 20px;
  text-align: center;
}

.content__back-link {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.3;
  font-family: "apercu-regular", Helvetica, sans-serif;
}
.content__back-link a {
  color: #999;
  font-weight: 100;
}

.content__preamble,
.content__preamble p, .content__body .content__preamble, .content__body .content__preamble p {
  font-family: "tiempos-regular";
  font-size: 1.3rem;
  line-height: 1.5;
  color: #aaa;
  margin-bottom: 2rem;
}

.content__body iframe {
  max-width: 100%;
}
.content__body h2 {
  font-size: 1.75rem;
  line-height: 1.2;
  margin-bottom: 0.25em;
}
.content__body h3 {
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 0.25em;
}
.content__body h4 {
  font-size: 1.25rem;
  line-height: 1.2;
  margin-bottom: 0.25em;
}
.content__body h5 {
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 0.25em;
}
.content__body p {
  font-size: 1.1rem;
  line-height: 1.5;
}
.content__body strong,
.content__body b {
  font-family: "apercu-bold";
  font-weight: normal;
}
.content__body strong em,
.content__body strong i,
.content__body b em,
.content__body b i {
  font-family: "apercu-bold-italic";
  font-weight: normal;
}
.content__body em,
.content__body i {
  font-family: "apercu-italic";
  font-weight: normal;
}
.content__body em strong,
.content__body em b,
.content__body i strong,
.content__body i b {
  font-family: "apercu-bold-italic";
  font-weight: normal;
}
.content__body ul,
.content__body ol {
  margin: 0 0 1.5rem;
}
.content__body ul {
  list-style: none !important;
  padding: 0;
}
.content__body ul li {
  background: transparent;
  font-family: "apercu-regular";
  list-style: none;
  margin: 0 0 0.3rem;
  padding-left: 1.5em;
  position: relative;
}
.content__body ul li p {
  margin: 0;
}
.content__body ul li::before {
  color: #666;
  content: "•";
  font-size: 3rem;
  left: 0;
  line-height: 0.7em;
  position: absolute;
  top: 0;
}
.content__body ol li {
  font-family: "apercu-regular";
  list-style: decimal;
  margin: 0 0 0.3rem 1.3em;
  padding-left: 0.2em;
}
.content__body ol li p {
  margin: 0;
}
.content__body .cta {
  border-radius: 2px;
}
.content__body .right-arrow {
  font-family: "apercu-medium";
}

.content__attached-files ul {
  list-style: none !important;
}

.content__body--frontpage {
  margin-bottom: 70px;
}

.content__image--right {
  float: right;
  margin: 0 0 10px 10px;
}

.content__image--left {
  float: left;
  margin: 0 10px 10px 0;
}

.content__figure {
  margin-bottom: 16px;
}

.content__caption {
  font-size: 0.9rem;
  line-height: 1.5;
}

.main-content a {
  color: #666;
  text-decoration: none;
}
.main-content a:hover {
  text-decoration: underline;
}

.primary-pushers__container {
  float: left;
  position: absolute;
  top: -540px;
  width: 100%;
}
.primary-pushers__container.primary-pushers__count--1 {
  top: -200px;
}
.primary-pushers__container.primary-pushers__count--2 {
  top: -360px;
}

.primary-pushers__sort-link {
  background: rgba(0, 0, 0, 0.42);
  border-bottom-right-radius: 3px;
  color: #fff;
  display: none;
  font-size: 15px;
  left: 0;
  padding: 6px 10px;
  position: absolute;
  text-decoration: none;
  top: 0;
}
.primary-pushers__container:hover .primary-pushers__sort-link {
  display: inline-block;
}
.primary-pushers__sort-link:hover {
  text-decoration: underline;
}

.front .header.primary-pushers__count--1 {
  padding-bottom: 200px;
}
.front .header.primary-pushers__count--2 {
  padding-bottom: 360px;
}
.front .header.primary-pushers__count--3 {
  padding-bottom: 540px;
}

.front .wrapper--content {
  position: relative;
}
.front .wrapper--content.primary-pushers__count--1 {
  padding-top: 50px;
}
.front .wrapper--content.primary-pushers__count--2 {
  padding-top: 120px;
}
.front .wrapper--content.primary-pushers__count--3 {
  padding-top: 160px;
}

.primary-pusher {
  background-color: #000;
  background-position: center;
  background-size: cover;
  border-bottom: 2px solid #fff;
  position: relative;
}
.primary-pusher__grid--1-1 .primary-pusher {
  border-bottom: 0;
}

.primary-pusher__inner-wrapper {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 80%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 80%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#00000000", GradientType=0);
  display: flex;
  height: 220px;
  padding: 15px;
  position: relative;
}
.primary-pusher__show-only-image .primary-pusher__inner-wrapper {
  align-items: center;
  opacity: 0;
}

.primary-pusher__title {
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 1.2;
  color: #fff;
  font-weight: 400;
  margin-bottom: 8px;
  word-wrap: break-word;
}
.primary-pusher__show-only-image .primary-pusher__title {
  display: none;
}

.primary-pusher__byline {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3;
  color: #fff;
  margin-bottom: 8px;
  opacity: 0.9;
}
.primary-pusher__show-only-image .primary-pusher__byline {
  display: none;
}

.primary-pusher__link {
  background: #666;
  color: #fff;
  display: inline-block;
  padding: 12px 18px 8px;
  position: relative;
  text-decoration: none;
}
.primary-pusher__link:hover {
  background: #545454;
}
.primary-pusher__show-only-image .primary-pusher__link {
  display: none;
}

.primary-pusher__content {
  width: 100%;
}
.secondary-pushers__list-item {
  margin-bottom: 20px;
}

.secondary-pushers__list-item--last {
  margin-bottom: 0;
}

.secondary-pusher__wrapper {
  background: #666;
  padding: 20px;
  position: relative;
}

.secondary-pusher__corner {
  border-left: 26px solid #4d4d4d;
  border-top: 26px solid #fff;
  position: absolute;
  right: 0;
  top: 0;
}

.secondary-pusher__link {
  color: #fff;
  float: left;
  text-decoration: none;
  width: 100%;
}
.secondary-pusher__link:hover .secondary-pusher__label {
  text-decoration: underline;
}

.secondary-pusher__prefix {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.4;
  color: #999;
}

.secondary-pusher__title {
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 1.1;
  color: #fff;
  font-weight: bold;
}

.secondary-pusher__suffix {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.4;
  color: #fff;
}

.secondary-pusher__label {
  float: right;
  margin-top: 20px;
}
.secondary-pusher__label::before {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1;
  content: "\e60d";
  float: right;
  font-family: "Lindholmen icons";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  margin-left: 6px;
  position: relative;
  text-transform: none;
  top: 6px;
}

.date-box {
  background: #fff;
  border-radius: 2px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 50px;
}

.date-box__pusher {
  min-height: auto;
  padding-left: 60px;
}
.event__list-item .date-box__pusher {
  min-height: 75px;
  padding-left: 60px;
}
.date-box__pusher .post__link {
  color: #333;
  text-decoration: none;
}
.date-box__pusher .post__link:hover {
  text-decoration: underline;
}

.date-box__year {
  background: #666;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  line-height: 1em;
  padding: 2px 0 1px;
}

.date-box__day {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.2;
  border: 1px solid #e5e5e5;
  border-width: 0 1px;
  font-weight: bold;
}

.date-box__month {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
  border: 1px solid #e5e5e5;
  border-radius: 0 0 2px 2px;
  border-width: 0 1px 1px;
  color: #666;
  text-transform: uppercase;
}

.form-text,
.form-file {
  border: 1px solid #ebebeb;
  color: #333;
  font-family: inherit;
  font-size: inherit;
  padding: 10px 14px;
  width: 100%;
}

.form-submit {
  -webkit-appearance: none;
  background: #333;
  border: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin-right: 10px;
  padding: 12px 30px;
  vertical-align: middle;
}
.form-submit:hover {
  background: #008c8d;
}

.form-item .description {
  color: #999;
}

.cke {
  max-width: none;
}

.webform-client-form label {
  font-family: "apercu-bold", "helvetica", sans-serif;
}
.webform-client-form .webform-component {
  float: left;
  width: 100%;
}
.webform-client-form .webform-component .form-item .description {
  font-size: 75%;
}
.webform-client-form .webform-component-select select {
  -webkit-appearance: none;
  background: transparent;
  border-radius: 2px;
  border: 1px solid #ebebeb;
  font-size: 0.9rem;
  height: 2rem;
  max-width: 100%;
  padding: 0 0.5rem;
}
.webform-client-form .webform-component-textfield,
.webform-client-form .webform-component-email {
  margin: 0 0 1rem;
}
.webform-client-form .webform-component-textfield .form-text,
.webform-client-form .webform-component-email .form-text {
  max-width: 100%;
  width: 100%;
}
.webform-client-form .webform-component-textfield .form-text:not([size="60"]),
.webform-client-form .webform-component-email .form-text:not([size="60"]) {
  width: auto;
}
.webform-client-form .webform-component-file {
  border-bottom: 1px solid #ebebeb;
  margin: 0 0 2rem;
  padding-bottom: 2rem;
}
.webform-client-form .webform-component-file label {
  margin-bottom: 1rem;
}
.webform-client-form .webform-component-file .form-managed-file {
  font-size: 80%;
}
.webform-client-form .webform-component-multifile {
  margin: 0;
}
.webform-client-form .webform-component-multifile .form-type-file {
  margin: 0;
}
.webform-client-form .webform-component-multifile .form-type-file label {
  font-size: 1.2rem;
}
.webform-client-form .webform-component-multifile .form-type-file .MultiFile-wrap {
  font-size: 80%;
}
.webform-client-form .webform-component-markup {
  font-size: 80%;
  margin: 0;
}
.webform-client-form .webform-component-markup h2 {
  margin: 1.5rem 0 2rem;
}
.webform-client-form .webform-component-markup h3 {
  font-size: 1.2rem;
  margin-left: 0;
}
.webform-client-form .webform-component-markup p {
  margin: 0 0 1rem;
}
.webform-client-form .webform-component-markup .accent {
  color: #f00;
}
.webform-client-form .form-radios,
.webform-client-form .form-checkboxes {
  font-size: 80%;
}
.webform-client-form .form-actions {
  float: left;
  margin-bottom: 4rem;
  width: 100%;
}
.webform-client-form .form-submit {
  -webkit-appearance: none;
  background: #666;
  margin-top: 0.5rem;
}
.webform-client-form .form-submit:hover {
  background: #333;
}

.event__list-item--teaser {
  margin-bottom: 20px;
}

.event__list-item--last {
  margin-bottom: 0;
}

.event__title--teaser {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.4;
}

.event__title--list {
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 5px;
}

.event__title--full {
  font-size: 46px;
  font-size: 2.875rem;
  line-height: 1.2;
  font-weight: 300;
  margin-bottom: 20px;
}

.event__label {
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 1.2;
  font-weight: 300;
  margin-bottom: 2px;
}
.event__label a {
  color: #333;
  text-decoration: none;
}
.event__label a:hover {
  text-decoration: underline;
}

.event__organizers {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.4;
}

.event__organizers--teaser {
  margin-top: 8px;
}

.event__organizers--full {
  font-size: 17px;
  font-size: 1.0625rem;
  line-height: 1.3;
}

.event__organizer {
  font-family: "apercu-medium";
}

.event__show-more-link {
  color: #666;
  float: right;
  text-decoration: none;
}
.event__show-more-link:hover {
  text-decoration: underline;
}
.event__show-more-link::before {
  content: "\e60d";
  float: right;
  font-family: "Lindholmen icons";
  padding-left: 5px;
  position: relative;
  top: 2px;
}

.events-archive__header {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.3;
  border-bottom: 1px solid #ebebeb;
  color: #000;
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 10px 13px 9px;
  text-decoration: none;
}

.sidebar__section--events-archive li {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.2;
  color: #999;
}
.sidebar__section--events-archive li a {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #333;
  text-decoration: none;
}
.sidebar__section--events-archive li a:hover {
  text-decoration: underline;
}

.event-archive__grouping-header {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2;
  margin-top: 10px;
  text-transform: capitalize;
}

.event-archive__list {
  margin-bottom: 20px;
}

.event-archive__list-item {
  border-bottom: 1px solid #ebebeb;
  margin-left: 10px;
  padding: 10px 0;
}

.event-archive__list-item:last-child {
  border-bottom: 0;
}

.event-archive__title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: 400;
}

.event-archive__date {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.2;
  color: #999;
}

.content__body img {
  height: auto !important;
  max-width: 100% !important;
}

img {
  height: auto;
  max-width: 100%;
}

.footer {
  background: #fafafa;
  border-top: 1px solid #ebebeb;
}

.footer__region {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 42px;
}
.footer__region a {
  color: #333;
  text-decoration: none;
}
.footer__region a:hover {
  text-decoration: underline;
}

.footer__region--bottom {
  border-top: 1px solid #ebebeb;
  color: #666;
  margin-top: 20px;
  padding: 14px 0 0;
}

.footer__lindholmen-logo {
  background: url("../../images/lsp-logo--black.svg") no-repeat 0 0;
  background-position: left center;
  background-size: contain;
  display: block;
  height: 82px;
  margin-bottom: 20px;
  text-indent: -9999px;
  width: 174px;
}

.video-container {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
}
.video-container iframe,
.video-container object,
.video-container embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.video-container--node {
  margin-bottom: 16px;
}

.video-container--pusher {
  padding-bottom: 220px;
  padding-top: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}
.primary-pusher__grid--1-1 .video-container--pusher {
  padding-bottom: 180px;
}

.video__play-button {
  padding-left: 25px;
}
.video__play-button::before {
  border-bottom: 7px solid transparent;
  border-left: 7px solid rgb(255, 255, 255);
  border-top: 7px solid transparent;
  content: "";
  display: block;
  float: left;
  height: 7px;
  position: relative;
  right: 8px;
  top: 6px;
  width: 7px;
}

.primary-pusher__show-only-image .video__play-button {
  background: rgba(0, 0, 0, 0.25);
  display: block;
  height: 100%;
  left: 0;
  padding-left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.primary-pusher__show-only-image .video__play-button::before {
  content: none;
}
.primary-pusher__show-only-image .video__play-button .video__play-icon {
  background: url("../../images/video-play-button.png") no-repeat 0 0;
  height: 72px;
  left: 50%;
  position: absolute;
  text-indent: -9999999px;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 72px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .primary-pusher__show-only-image .video__play-button .video__play-icon {
    background-image: url("../../images/video-play-button@2x.png");
    background-size: 72px 72px;
  }
}

.item-list .pager li {
  margin-left: 0;
}

.pager-current {
  font-weight: normal;
}

.blog-type__header::after {
  clear: both;
  content: " ";
  display: block;
  height: 0;
  visibility: hidden;
}

.blog-type__title {
  float: left;
}

.blog-type__feed {
  float: right;
}

.google-map {
  height: 500px;
  margin-bottom: 50px;
}
.google-map--dynamic-map {
  float: left;
  width: 100%;
}
.google-map__marker {
  display: none;
}

.responsive-embed {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  margin-bottom: 1.5rem;
}
.responsive-embed iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}
.responsive-embed + .content__caption {
  margin: -1.2rem 0 1.5rem;
}

p + .responsive-embed {
  margin-top: -1rem;
}

.cookieconsent-optout-marketing {
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
  display: block;
}
.cookieconsent-optout-marketing .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.8);
}
.cookieconsent-optout-marketing .play-icon {
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .cookieconsent-optout-marketing .play-icon {
    background-image: url("../../images/video-play-button@2x.png");
    background-size: 35px 35px;
  }
}
.cookieconsent-optout-marketing .top {
  position: absolute;
  z-index: 101;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
}
.cookieconsent-optout-marketing .top .logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.cookieconsent-optout-marketing .top .logo img {
  border-radius: 50%;
}
.cookieconsent-optout-marketing .top .title {
  font-size: 1.2rem;
  line-height: 1.3;
  padding-right: 10px;
}
.cookieconsent-optout-marketing .consent-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 2%;
  z-index: 101;
  color: black;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.8rem;
  width: 90%;
  text-align: center;
}
.cookieconsent-optout-marketing .consent-wrapper .cta {
  margin-top: 5px;
  font-size: 1rem;
  display: block;
  padding: 5px 20px !important;
}

.social-share {
  float: left;
  margin: 35px 0 25px;
  width: 100%;
}
.social-share::after {
  clear: both;
  content: "";
  display: table;
}
.social-share__link {
  display: block;
  float: left;
  font-family: "Lindholmen icons";
  font-size: 0;
  -webkit-font-smoothing: antialiased;
  height: 35px;
  margin-right: 15px;
  width: 35px;
}
.main-content .social-share__link {
  color: #666;
}
.main-content .social-share__link:hover {
  color: #666;
  text-decoration: none;
}
.social-share__link::before {
  font-size: 35px;
  line-height: 35px;
}
.social-share__link--facebook::before {
  content: "\e604";
}
.social-share__link--twitter::before {
  content: "\e601";
}
.social-share__link--linkedin::before {
  content: "\e603";
}

.grid-item {
  background-color: #666;
  background-position: center;
  background-size: cover;
  display: block;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}
.main-content .grid-item {
  color: #fff;
}
.grid-item__content {
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  padding: 20px;
  position: absolute;
  width: 100%;
}
.grid-item__content::before {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, transparent 100%);
  content: " ";
  display: block;
  height: 2rem;
  left: 0;
  position: absolute;
  top: -2rem;
  width: 100%;
}
.grid-item__title {
  color: #fff;
  font-size: 1.3rem;
  line-height: 1.8rem;
}
.grid-item__byline {
  font-size: 0.9rem;
  line-height: 1.5;
  margin-bottom: 0;
}

.resource-grid__header p {
  max-width: 790px;
}
.resource-grid__filters .form-item {
  float: left;
  margin-right: 50px;
}
.resource-grid__filters .form-item:last-child {
  margin-right: 0;
}
.resource-grid__filters .form-item .option {
  text-transform: uppercase;
}
.resource-grid__filters .form-item .option:hover {
  cursor: pointer;
}
.resource-grid__filters .form-item .form-radio {
  display: none;
}
.resource-grid__filters .form-item .form-radio:checked + .option {
  text-decoration: underline;
}
.resource-grid__filters .ajax-progress .throbber {
  filter: grayscale(100%);
}
.resource-grid__item {
  float: left;
  padding-bottom: 30px;
  width: 100%;
}

.swiper-container--slider {
  width: 100%;
}
.swiper-container--slider.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}
.swiper-container--slider .swiper-pagination-bullet-active {
  background-color: #666;
}
.swiper-container--slider figure {
  margin-bottom: 16px;
}
.swiper-container--slider img {
  height: auto !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 100% !important;
  width: 100% !important;
}
.swiper-container--with-caption {
  margin-bottom: 4rem !important;
}
.swiper-container--with-caption .content__caption {
  font-family: tiempos-regular, georgia, serif;
  margin-bottom: 1.8rem;
}
.list-filter {
  list-style: none;
  margin: 0 0 2rem;
  overflow: hidden;
  padding: 0;
}
.list-filter--center {
  text-align: center;
}
.page-events-archive .list-filter--center {
  text-align: left;
}
.list-filter__item {
  float: left;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}
.list-filter--center .list-filter__item {
  float: none;
  display: inline-block;
}
.list-filter__btn {
  border-radius: 2px;
  border: 2px solid #666;
  color: #666;
  cursor: pointer;
  float: left;
  font-family: "apercu-medium";
  font-size: 0.9rem;
  line-height: normal;
  outline: none;
  padding: 0.4rem 0.8rem;
  position: relative;
  transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.list-filter__btn:hover, .list-filter__btn.is-active {
  background: #666;
  color: #fff;
  text-decoration: none !important;
}
.list-filter__btn:active {
  top: 1px;
}

.card {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 10px 38px rgba(0, 26, 100, 0.05);
  display: block;
  float: left;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  overflow: hidden;
  position: relative;
  text-decoration: none;
}
.l-section--darkblue .card {
  background-color: #000d37;
}
.card__img {
  position: relative;
  transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.card__img img {
  display: block;
  width: 100%;
}
.program-portfolio .card__img::after, .view-display-id-graphs .card__img::after {
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.card__img-placeholder {
  background: #000d37;
  padding-top: 66.67%;
}
.card__date {
  background: #0ed3d9;
  border-radius: 2px;
  color: #fff;
  font-family: "apercu-medium";
  font-size: 1rem;
  height: 4.5rem;
  left: 1.5rem;
  line-height: 2.25rem;
  overflow: hidden;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 1.5rem;
  width: 4.5rem;
}
.card__date span {
  display: block;
  height: 2.25rem;
  width: 100%;
}
.card__date span:nth-child(1) {
  background: rgba(0, 0, 0, 0.12);
}
.card .date-all-day-label {
  display: none;
}
.card__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 160px;
  padding: 1.5rem;
}
.l-section--darkblue .card__content {
  background-color: #fff;
}
.program-portfolio .card__content, .view-display-id-graphs .card__content {
  bottom: 0;
  height: 100%;
  left: 0;
  min-height: 0;
  position: absolute;
  width: 100%;
}
.card__content .icon-before {
  line-height: 1.5;
  padding-left: 25px;
  position: relative;
}
.card__content .icon-before::before {
  left: 0;
  line-height: 1.2;
  position: absolute;
}
.card__content .icon-before--pin::before {
  left: 1px;
}
.program-portfolio .card__heading, .view-display-id-graphs .card__heading {
  bottom: 0;
  color: #fff;
  left: 0;
  line-height: 1.2;
  padding: 2rem;
  position: absolute;
  width: 100%;
}
.card__title {
  font-family: "apercu-medium";
  font-size: 1.4rem;
  line-height: 1.2;
  margin: 0 0 0.33rem;
}
.program-portfolio .card__title, .view-display-id-graphs .card__title {
  color: #fff;
}
.card__sub-title {
  margin: 0;
  font-size: 1rem;
}
.card__sub-title::before {
  font-size: 0.9rem;
}
.node-program-pusher .card__sub-title {
  margin-bottom: 0.33rem;
}
.node-event .card__sub-title {
  line-height: 1.5;
}
.card__sub-title--larger {
  font-size: 1.15rem;
}
.card__meta {
  margin: 0 0 1rem;
}
.card__stamp {
  background: #53E5AF;
  border-radius: 4px;
  display: inline-block;
  font-family: "apercu-medium";
  font-size: 0.7rem;
  line-height: 1;
  margin-right: 0.075rem;
  padding: 0.25rem 0.3rem 0.2rem;
  position: relative;
  text-transform: uppercase;
  top: -0.1rem;
}
.card__teaser {
  font-family: "tiempos-regular";
}
.program-portfolio .card__bottom, .view-display-id-graphs .card__bottom {
  display: none;
}
.card__bottom a {
  display: block;
}
.node-contact-person .card__bottom a {
  font-size: 1.1rem;
}
.card__read-more {
  font-family: "apercu-medium";
}
.card__read-more--external::after {
  background-image: url("../../images/external.svg");
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: inline-block;
  height: 1rem;
  margin-left: 0.5rem;
  position: relative;
  top: 0.05em;
  width: 1rem;
}
.js-animate .card {
  transform: translate3d(0, -1rem, 0);
  transition-duration: 3s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.is-visible .card {
  opacity: 1 !important;
  transform: translate3d(0, 0, 0);
}
.card:hover {
  text-decoration: none !important;
}
.card:hover .card__img {
  opacity: 0.75;
}

.card.contacts__person:hover .card__img {
  opacity: 1;
}

.node-type-portfolio-type .card-list, .view-display-id-graphs .card-list {
  margin-top: 5rem;
}
.card-list::after {
  content: "";
  display: block;
}
.card-list .view-content {
  margin-left: -1rem;
  margin-right: -1rem;
  width: calc(100% + 2rem);
}
.card-list .view-content div:nth-child(1) .card {
  transition-delay: 0.15s;
}
.card-list .view-content div:nth-child(2) .card {
  transition-delay: 0.3s;
}
.card-list .view-content div:nth-child(3) .card {
  transition-delay: 0.45s;
}
.card-list .view-content div:nth-child(4) .card {
  transition-delay: 0.6s;
}
.card-list .view-content div:nth-child(5) .card {
  transition-delay: 0.75s;
}
.card-list .view-content div:nth-child(6) .card {
  transition-delay: 0.9s;
}
.card-list .view-content div:nth-child(7) .card {
  transition-delay: 1.15s;
}
.card-list .view-content div:nth-child(8) .card {
  transition-delay: 1.3s;
}

.card-list.card-list--2-col {
  margin: 0 auto;
  max-width: 1200px;
}
.call-out-box {
  background: #F7F8FA;
  border-left: 4px solid #53E5AF;
  border-radius: 2px;
  font-family: "apercu-regular";
  margin: 3rem 0;
  padding: 2rem 2.2rem 1.8rem;
}
.call-out-box p {
  margin: 0 0 1.5rem;
}
.call-out-box p:last-child {
  margin: 0;
}
.call-out-box a {
  color: #000d37;
  font-family: "apercu-medium";
}

blockquote {
  line-height: 1.6;
  margin: 4rem 0 3rem;
  position: relative;
}
blockquote,
blockquote p, .content__body blockquote, .content__body blockquote p {
  font-size: 1.3rem;
  font-family: "apercu-italic";
  line-height: 1.6;
}
blockquote::before {
  background-image: url("../../images/quote-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: block;
  height: 8rem;
  margin: -2rem 0 0 -1.2rem;
  position: absolute;
  width: 7rem;
}

.content__body .faq-question {
  color: inherit;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.1875;
  border-bottom: 1px solid #D8D8D8;
  padding: 1.4375rem 0;
  margin-bottom: 1.4375rem;
}
.content__body .faq-question::before {
  content: "+";
  color: #333333;
  margin-right: 0.6rem;
}
.content__body .faq-question.is-first {
  border-top: 1px solid #D8D8D8;
}
.content__body .faq-question.is-open {
  border-bottom: 0;
  margin-bottom: 0;
}
.content__body .faq-question.is-open::before {
  content: "−";
}
.content__body .faq-answer {
  font-size: 1.3rem;
  line-height: 1.6;
  margin: 0;
  padding-bottom: 1.4375rem;
}
.content__body .faq-answer.is-last {
  margin-bottom: 1.4375rem;
  border-bottom: 1px solid #D8D8D8;
}
.content__body .faq-answer + .faq-question {
  margin-top: -1.4375rem;
}

table.tableList {
  width: 100%;
  overflow: auto;
}
table.tableList tr {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.4;
}
table.tableList tr:first-child {
  filter: brightness(85%);
}
table.tableList tr:nth-child(odd) {
  background: #F4F2E6;
}
table.tableList td {
  padding: 7px;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.4;
  line-height: 1.25em;
}

.flag-forum-report-node,
.flag-forum-report-comment, .forum-post-links ul.links, .wrapper, .admin-tabs, .header__container, .header__mobile-lindholmen-logo, .mobile-menu__wrapper .navigation__menu-tree, .news__more-link-wrapper {
  *zoom: 1;
}
.flag-forum-report-node:before,
.flag-forum-report-comment:before, .forum-post-links ul.links:before, .wrapper:before, .admin-tabs:before, .header__container:before, .header__mobile-lindholmen-logo:before, .mobile-menu__wrapper .navigation__menu-tree:before, .news__more-link-wrapper:before, .flag-forum-report-node:after,
.flag-forum-report-comment:after, .forum-post-links ul.links:after, .wrapper:after, .admin-tabs:after, .header__container:after, .header__mobile-lindholmen-logo:after, .mobile-menu__wrapper .navigation__menu-tree:after, .news__more-link-wrapper:after {
  content: " ";
  display: table;
}
.flag-forum-report-node:after,
.flag-forum-report-comment:after, .forum-post-links ul.links:after, .wrapper:after, .admin-tabs:after, .header__container:after, .header__mobile-lindholmen-logo:after, .mobile-menu__wrapper .navigation__menu-tree:after, .news__more-link-wrapper:after {
  clear: both;
}

.domain-civic .header__mobile-lindholmen-logo,
.domain-civic .header__desktop-lindholmen-logo {
  display: none;
}
.domain-civic .header__container {
  background: #fff;
}
.domain-civic .header__site-link,
.domain-civic .language-switch__link,
.domain-civic .navigation__mobile-toggle-label {
  color: #000;
}
.domain-civic .navigation__mobile-hamburger {
  background: #000;
}
.domain-civic .navigation__mobile-hamburger::before, .domain-civic .navigation__mobile-hamburger::after {
  background: #000;
}
.domain-civic.mobile-nav--is-open .navigation__mobile-hamburger {
  background: transparent;
}
.domain-civic .header__site-link {
  background: url("../../images/site-specific/civic-logo.png") no-repeat;
  background-size: 100%;
  display: block;
  height: 32px;
  margin-top: 5px;
  text-indent: -99999px;
  width: 64px;
  height: 64px;
  width: 127px;
}

.domain-mimic .header__mobile-lindholmen-logo,
.domain-mimic .header__desktop-lindholmen-logo {
  display: none;
}
.domain-mimic .header__container {
  background: #fff;
}
.domain-mimic .header__site-link,
.domain-mimic .language-switch__link,
.domain-mimic .navigation__mobile-toggle-label {
  color: #000;
}
.domain-mimic .navigation__mobile-hamburger {
  background: #000;
}
.domain-mimic .navigation__mobile-hamburger::before, .domain-mimic .navigation__mobile-hamburger::after {
  background: #000;
}
.domain-mimic.mobile-nav--is-open .navigation__mobile-hamburger {
  background: transparent;
}

.domain-electricity-lindholmen-se .header__mobile-lindholmen-logo,
.domain-electricity-lindholmen-se .header__desktop-lindholmen-logo {
  display: none;
}
.domain-electricity-lindholmen-se .header__container {
  background: #fff;
}
.domain-electricity-lindholmen-se .header__site-link,
.domain-electricity-lindholmen-se .navigation__mobile-toggle-label {
  color: #666;
}
.domain-electricity-lindholmen-se .language-switch__link {
  color: #fff;
}
.domain-electricity-lindholmen-se .navigation__mobile-hamburger {
  background: #666;
}
.domain-electricity-lindholmen-se .navigation__mobile-hamburger::before, .domain-electricity-lindholmen-se .navigation__mobile-hamburger::after {
  background: #666;
}
.domain-electricity-lindholmen-se.mobile-nav--is-open .navigation__mobile-hamburger {
  background: transparent;
}
.domain-electricity-lindholmen-se .custom-mod__clearfix {
  clear: left;
}

.domain-drivesweden .header__mobile-lindholmen-logo,
.domain-drivesweden .header__desktop-lindholmen-logo {
  display: none;
}
.domain-drivesweden .header__container {
  background: #fff;
}
.domain-drivesweden .header__site-link,
.domain-drivesweden .navigation__mobile-toggle-label {
  color: #666;
}
.domain-drivesweden .language-switch__link {
  color: #fff;
}
.domain-drivesweden .navigation__mobile-hamburger {
  background: #666;
}
.domain-drivesweden .navigation__mobile-hamburger::before, .domain-drivesweden .navigation__mobile-hamburger::after {
  background: #666;
}
.domain-drivesweden.mobile-nav--is-open .navigation__mobile-hamburger {
  background: transparent;
}
.domain-drivesweden .custom-mod__clearfix {
  clear: left;
}

.domain-softwareday .header__site-name {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.1;
  max-width: 68%;
}
.domain-softwareday .header__site-link img {
  max-height: none;
  max-width: 600px;
}
.domain-softwareday .secondary-pusher__prefix {
  color: rgba(255, 255, 255, 0.5);
}
.domain-softwareday .content__body--frontpage {
  margin-bottom: 35px;
}
.domain-softwareday .content__body--frontpage p {
  line-height: 1.5;
}

.domain-visualarena .header {
  background-color: transparent;
  background-image: url("../../images/site-specific/visualarena-header-bg.jpg");
  background-position: 0 -380px;
  background-repeat: no-repeat;
  background-size: 2560px;
}
.domain-visualarena.mobile-nav--is-open .language-switch--mobile .language-switch__link {
  margin-top: 20px;
}
.domain-visualarena .navigation__mobile-button {
  background-color: rgba(0, 0, 0, 0.2);
}
.domain-visualarena .mobile-menu__wrapper .navigation__menu-tree {
  background-color: rgba(0, 0, 0, 0.4);
}
.domain-visualarena .mobile-menu__wrapper .navigation__menu-tree .leaf a.active, .domain-visualarena .mobile-menu__wrapper .navigation__menu-tree .leaf a.active-trail,
.domain-visualarena .mobile-menu__wrapper .navigation__menu-tree .expanded a.active,
.domain-visualarena .mobile-menu__wrapper .navigation__menu-tree .expanded a.active-trail,
.domain-visualarena .mobile-menu__wrapper .navigation__menu-tree .collapsed a.active,
.domain-visualarena .mobile-menu__wrapper .navigation__menu-tree .collapsed a.active-trail {
  background: rgba(255, 255, 255, 0.3);
}
.domain-visualarena .mobile-menu__wrapper .navigation__menu-tree .leaf a:hover,
.domain-visualarena .mobile-menu__wrapper .navigation__menu-tree .expanded a:hover,
.domain-visualarena .mobile-menu__wrapper .navigation__menu-tree .collapsed a:hover {
  background: rgba(255, 255, 255, 0.3);
}
.domain-visualarena .mobile-menu__wrapper .navigation__hidden-menu-links .leaf a.active, .domain-visualarena .mobile-menu__wrapper .navigation__hidden-menu-links .leaf a.active-trail,
.domain-visualarena .mobile-menu__wrapper .navigation__hidden-menu-links .expanded a.active,
.domain-visualarena .mobile-menu__wrapper .navigation__hidden-menu-links .expanded a.active-trail,
.domain-visualarena .mobile-menu__wrapper .navigation__hidden-menu-links .collapsed a.active,
.domain-visualarena .mobile-menu__wrapper .navigation__hidden-menu-links .collapsed a.active-trail {
  background-color: rgba(0, 0, 0, 0.1);
}
.domain-visualarena .mobile-menu__wrapper .navigation__hidden-menu-links .leaf a:hover,
.domain-visualarena .mobile-menu__wrapper .navigation__hidden-menu-links .expanded a:hover,
.domain-visualarena .mobile-menu__wrapper .navigation__hidden-menu-links .collapsed a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.domain-visualarena .primary-pusher__grid .primary-pusher {
  border: 2px solid #fff;
}
.domain-visualarena .primary-pusher__grid--2-2 .primary-pusher,
.domain-visualarena .primary-pusher__grid--3-2 .primary-pusher,
.domain-visualarena .primary-pusher__grid--3-3 .primary-pusher {
  border-top: 0;
}
.domain-visualarena .secondary-pusher__prefix {
  color: rgba(255, 255, 255, 0.6);
}

.domain-manusfabriken {
  font-family: "Droid Serif", "Georgia", serif;
}
.domain-manusfabriken .header__container {
  background: #fff;
}
.domain-manusfabriken .header__mobile-lindholmen-logo,
.domain-manusfabriken .header__desktop-lindholmen-logo {
  display: none;
}
.domain-manusfabriken .header__site-name {
  float: none;
}
.domain-manusfabriken .navigation__mobile-button {
  float: left;
}
.domain-manusfabriken .navigation__mobile-toggle-label {
  color: #000;
}
.domain-manusfabriken .language-switch__link {
  color: #fff;
}
.domain-manusfabriken .navigation__mobile-hamburger {
  background: #000;
}
.domain-manusfabriken .navigation__mobile-hamburger::before, .domain-manusfabriken .navigation__mobile-hamburger::after {
  background: #000;
}
.domain-manusfabriken.mobile-nav--is-open .navigation__mobile-hamburger {
  background: transparent;
}
.domain-manusfabriken .header__site-link {
  background: url("../../images/site-specific/manusfabriken-logo-mobile.png") no-repeat;
  background-size: 100%;
  display: block;
  height: 53px;
  margin-bottom: 10px;
  margin-top: 5px;
  text-indent: -99999px;
  width: 280px;
  background-image: url("../../images/site-specific/manusfabriken-logo-desktop.png");
  height: 60px;
  margin-bottom: 20px;
  width: 430px;
}
.domain-manusfabriken.front .wrapper--content {
  padding-top: 0;
}
.domain-manusfabriken.front .content__title {
  margin-bottom: 22px;
}
.domain-manusfabriken .content__body a {
  color: #d0021b;
}
.domain-manusfabriken .content__body a:hover {
  text-decoration: underline;
}
.domain-manusfabriken .main-content .cta {
  background: #d0021b;
  color: #fff;
  font-family: "apercu-regular", "Helvetica", sans-serif;
  padding: 15px 30px 10px;
  text-decoration: none;
}
.domain-manusfabriken .content__body .cta:hover,
.domain-manusfabriken .content__body .cta-arrow:hover,
.domain-manusfabriken .content__body .cta-arrow-short:hover {
  background-color: #8a0101;
  text-decoration: none;
}
.domain-manusfabriken .secondary-pusher__prefix {
  color: rgba(255, 255, 255, 0.6);
}
.domain-manusfabriken .main-content .content__blog-list .news__super-link {
  color: #333;
}

.domain-film.page-our-projects .main-content, .domain-film.node-type-resource-film-tv .main-content {
  width: 100%;
}
.domain-film.page-our-projects .sidebar, .domain-film.node-type-resource-film-tv .sidebar {
  display: none;
}

.forum-topic-header .last-post-link {
  margin: 0;
}

.forum-topic-header .button,
.forum-node-create-links .button {
  display: inline-block;
  margin-right: 8px;
}

.button + .button,
.last-post-link a {
  margin-right: 8px;
}

.af-button-large, .af-button-small, .author-pane-link {
  font-family: inherit;
}

#advanced-forum-forum-topic-list-sort-form#advanced-forum-forum-topic-list-sort-form input {
  padding: 5px 10px !important;
}

.forum-post-links {
  line-height: 1.3;
  margin: 0;
  text-align: left;
}
.forum-post-links ul.links {
  margin: 0;
}
.forum-post-links ul.links li {
  background: none;
  float: right;
  padding-left: 0;
}

.comment-add a.af-button-small,
.comment-add .af-button-small,
.comment-add a,
.comment-reply a.af-button-small,
.comment-reply .af-button-small,
.comment-reply a,
.comment-edit a.af-button-small,
.comment-edit .af-button-small,
.comment-edit a,
.comment-delete a.af-button-small,
.comment-delete .af-button-small,
.comment-delete a,
.reportabuse a.af-button-small,
.reportabuse .af-button-small,
.reportabuse a,
.post-edit a.af-button-small,
.post-edit .af-button-small,
.post-edit a,
.post-delete a.af-button-small,
.post-delete .af-button-small,
.post-delete a {
  background: #eee;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  display: block;
  line-height: normal;
  margin-left: 1px;
  margin-top: 1px;
  padding: 4px 12px;
  text-shadow: none;
}
.comment-add a.af-button-small span,
.comment-add .af-button-small span,
.comment-add a span,
.comment-reply a.af-button-small span,
.comment-reply .af-button-small span,
.comment-reply a span,
.comment-edit a.af-button-small span,
.comment-edit .af-button-small span,
.comment-edit a span,
.comment-delete a.af-button-small span,
.comment-delete .af-button-small span,
.comment-delete a span,
.reportabuse a.af-button-small span,
.reportabuse .af-button-small span,
.reportabuse a span,
.post-edit a.af-button-small span,
.post-edit .af-button-small span,
.post-edit a span,
.post-delete a.af-button-small span,
.post-delete .af-button-small span,
.post-delete a span {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.4;
  background: none;
  color: #000;
  display: block;
  line-height: normal;
  margin: 0;
  padding: 0;
  text-shadow: none;
}
.comment-add a.af-button-small span:first-letter,
.comment-add .af-button-small span:first-letter,
.comment-add a span:first-letter,
.comment-reply a.af-button-small span:first-letter,
.comment-reply .af-button-small span:first-letter,
.comment-reply a span:first-letter,
.comment-edit a.af-button-small span:first-letter,
.comment-edit .af-button-small span:first-letter,
.comment-edit a span:first-letter,
.comment-delete a.af-button-small span:first-letter,
.comment-delete .af-button-small span:first-letter,
.comment-delete a span:first-letter,
.reportabuse a.af-button-small span:first-letter,
.reportabuse .af-button-small span:first-letter,
.reportabuse a span:first-letter,
.post-edit a.af-button-small span:first-letter,
.post-edit .af-button-small span:first-letter,
.post-edit a span:first-letter,
.post-delete a.af-button-small span:first-letter,
.post-delete .af-button-small span:first-letter,
.post-delete a span:first-letter {
  text-transform: uppercase;
}
.comment-add a.af-button-small:hover,
.comment-add .af-button-small:hover,
.comment-add a:hover,
.comment-reply a.af-button-small:hover,
.comment-reply .af-button-small:hover,
.comment-reply a:hover,
.comment-edit a.af-button-small:hover,
.comment-edit .af-button-small:hover,
.comment-edit a:hover,
.comment-delete a.af-button-small:hover,
.comment-delete .af-button-small:hover,
.comment-delete a:hover,
.reportabuse a.af-button-small:hover,
.reportabuse .af-button-small:hover,
.reportabuse a:hover,
.post-edit a.af-button-small:hover,
.post-edit .af-button-small:hover,
.post-edit a:hover,
.post-delete a.af-button-small:hover,
.post-delete .af-button-small:hover,
.post-delete a:hover {
  background: #ccc;
}
.comment-add a.af-button-small:hover span,
.comment-add .af-button-small:hover span,
.comment-add a:hover span,
.comment-reply a.af-button-small:hover span,
.comment-reply .af-button-small:hover span,
.comment-reply a:hover span,
.comment-edit a.af-button-small:hover span,
.comment-edit .af-button-small:hover span,
.comment-edit a:hover span,
.comment-delete a.af-button-small:hover span,
.comment-delete .af-button-small:hover span,
.comment-delete a:hover span,
.reportabuse a.af-button-small:hover span,
.reportabuse .af-button-small:hover span,
.reportabuse a:hover span,
.post-edit a.af-button-small:hover span,
.post-edit .af-button-small:hover span,
.post-edit a:hover span,
.post-delete a.af-button-small:hover span,
.post-delete .af-button-small:hover span,
.post-delete a:hover span {
  color: #000;
}
.flag-forum-report-node,
.flag-forum-report-comment {
  background: #eee;
  display: block;
  margin-top: 1px;
  padding: 4px 12px;
}
.flag-forum-report-node.flag-waiting,
.flag-forum-report-comment.flag-waiting {
  padding-right: 23px;
}
.flag-forum-report-node .flag,
.flag-forum-report-comment .flag {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.4;
  display: block;
  float: left;
}
.flag-forum-report-node .flag-action,
.flag-forum-report-node .unflag-action,
.flag-forum-report-comment .flag-action,
.flag-forum-report-comment .unflag-action {
  color: #000;
}
.flag-forum-report-node .flag-throbber,
.flag-forum-report-comment .flag-throbber {
  float: left;
  position: absolute;
  top: 1px;
}
.flag-forum-report-node:hover,
.flag-forum-report-comment:hover {
  background: #d8d7d7;
}

.forum-table-wrap {
  margin-bottom: 16px;
}

.forum-topic-header .topic-reply-link,
.forum-topic-header .topic-new-post-link,
.forum-topic-header .topic-post-count {
  margin: 0;
  padding: 0;
}

#forum-topic-header .topic-reply-link {
  float: right;
}
#forum-topic-header .topic-reply-link a {
  display: block;
  margin-bottom: 8px;
}

#forum-topic-header,
.forum-node-create-links {
  margin: 0px 0px 16px;
  padding: 0;
  width: 100%;
}
#forum-topic-header .button,
.forum-node-create-links .button {
  margin-bottom: 8px;
}

#forum-topic-header {
  margin-bottom: 0;
}

.page-forum .l-content,
.node-type-forum .l-content {
  padding: 0;
}
.page-forum .page-title,
.node-type-forum .page-title {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.3;
}
.page-forum .pane-forum-topic-list,
.node-type-forum .pane-forum-topic-list {
  margin: 0;
}

.forum-table-wrap .forum-table-superheader,
.forum-table-topics thead tr {
  background: #eeeeee;
  border-bottom: none;
}

.forum-header th, .forum-table thead tr th {
  padding: 8px;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.3;
}

.forum-table {
  display: table;
}

.forum-table tbody {
  display: table-row-group;
}

.forum-table tbody td {
  border: none;
  padding: 8px;
  vertical-align: middle;
}

.forum-table tbody tr.forum-row {
  padding: 0;
}

.forum-table tbody .odd {
  background: #f9f9f9;
}

.forum-table tbody .even {
  background: #fff;
}

.form-textarea {
  border: 1px solid #ebebeb;
}

#forum-sort {
  float: none;
  margin: 20px 0;
}

#forum-pager-top .pager,
#forum-pager-bottom .pager {
  padding: 0;
}

.forum-pager .pager {
  margin: 10px 0;
  width: 100%;
}

.forum-tools {
  float: none;
  margin: 10px 0;
}
.forum-tools #edit-jump {
  width: 100%;
}

.forum__flagged-title {
  padding: 8px 16px;
  margin: 0;
}

#forum-comments .pager li {
  padding: 0;
}

.forum-pager .pager a,
.forum-pager .pager .pager-current,
#forum-comments .pager a,
#forum-comments .pager .pager-current {
  padding: 1px 4px;
}
.forum-pager .pager li,
#forum-comments .pager li {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3;
}

th.views-field-last-updated, th.forum-last-post, th.forum-last-reply, th.forum-icon, th.forum-list-icon, th.views-field-topic-icon, td.views-field-last-updated, td.forum-last-post, td.forum-last-reply, td.forum-icon, td.forum-list-icon, td.views-field-topic-icon {
  display: none;
}

.forum__date {
  color: #515151;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.3;
}

.forum__notification-badge {
  background: #ababab;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  margin-left: 4px;
  padding: 3px 4px;
}

.forum-table-topics td.views-field-title .forum__user--list a {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.3;
}

.forum--reported-content {
  display: block;
  margin-bottom: 30px;
}

span.forum-collapsible {
  display: none;
}

td.forum-details .forum-description {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.3;
}

.forum-table thead tr th.forum-icon  {
  padding-right: 0;
}

tr.odd td.forum-list-icon-default.forum-list-icon,
tr.even td.forum-list-icon-default.forum-list-icon,
tr.odd td.forum-list-icon-new-posts.forum-list-icon,
tr.even td.forum-list-icon-new-posts.forum-list-icon {
  padding-right: 0;
  width: 24px;
}
tr.odd td.forum-list-icon-default .forum-list-icon-wrapper,
tr.even td.forum-list-icon-default .forum-list-icon-wrapper,
tr.odd td.forum-list-icon-new-posts .forum-list-icon-wrapper,
tr.even td.forum-list-icon-new-posts .forum-list-icon-wrapper {
  border: none;
  box-shadow: none;
  display: inline-block;
  margin: 0;
}
tr.odd td.forum-list-icon-default .forum-list-icon-wrapper, tr.odd td.forum-list-icon-default .forum-list-icon-wrapper span,
tr.even td.forum-list-icon-default .forum-list-icon-wrapper,
tr.even td.forum-list-icon-default .forum-list-icon-wrapper span,
tr.odd td.forum-list-icon-new-posts .forum-list-icon-wrapper,
tr.odd td.forum-list-icon-new-posts .forum-list-icon-wrapper span,
tr.even td.forum-list-icon-new-posts .forum-list-icon-wrapper,
tr.even td.forum-list-icon-new-posts .forum-list-icon-wrapper span {
  height: 26px;
  width: 26px;
}
tr.odd td.forum-list-icon-default .forum-list-icon-wrapper span,
tr.even td.forum-list-icon-default .forum-list-icon-wrapper span,
tr.odd td.forum-list-icon-new-posts .forum-list-icon-wrapper span,
tr.even td.forum-list-icon-new-posts .forum-list-icon-wrapper span {
  background-position: center;
  background-repeat: no-repeat;
}

tr.odd td.forum-list-icon-default .forum-list-icon-wrapper span,
tr.even td.forum-list-icon-default .forum-list-icon-wrapper span {
  background-image: url("../../images/forum--no-new-posts.png");
}

tr.odd td.forum-list-icon-new-posts .forum-list-icon-wrapper span,
tr.even td.forum-list-icon-new-posts .forum-list-icon-wrapper span {
  background-image: url("../../images/forum--new-posts.png");
}

.forum-list-icon-legend div {
  margin-bottom: 8px;
}

.forum-list-icon-legend .forum-list-icon-wrapper {
  border: none;
  box-shadow: none;
  display: inline-block;
  margin: 0;
}
.forum-list-icon-legend .forum-list-icon-wrapper, .forum-list-icon-legend .forum-list-icon-wrapper span {
  width: 26px;
  height: 26px;
}
.forum-list-icon-legend .forum-list-icon-wrapper span {
  background-position: center;
  background-repeat: no-repeat;
}
.forum-list-icon-legend .forum-list-icon-wrapper .forum-list-icon-new-posts {
  background-image: url("../../images/forum--new-posts.png");
}
.forum-list-icon-legend .forum-list-icon-wrapper .forum-list-icon-default {
  background-image: url("../../images/forum--no-new-posts.png");
}

.view-forum-search .views-exposed-widget .form-submit {
  margin-top: 0;
}

#forum-statistics  {
  border: 1px solid #c1c1c1;
}

#forum-statistics-header {
  background: #eeeeee;
  border-top: 0;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.3;
  padding: 12px;
}

.forum-statistics-sub-header {
  padding: 8px 16px;
}

.forum-statistics-sub-body {
  background: #f9f9f9;
  padding: 8px 24px;
}

.forum-topic-header-wrapper {
  position: relative;
}
.forum-topic-header-wrapper .forum-topic__subscribe-link {
  float: right;
}

.form-item-node-notify-subscribe {
  margin-bottom: 15px;
}

.forum-topic-header .topic-post-count {
  float: left;
  margin: 10px 0;
}

div.forum-post {
  border: 0;
  margin-bottom: 16px;
}
div.forum-post .forum-post-info {
  padding: 4px 8px;
  background: #eeeeee;
  border: 1px solid #C1C1C1;
  border-bottom: 0;
}
div.forum-post .forum-post-info .forum-posted-on,
div.forum-post .forum-post-info .forum-post-number {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.2;
}
div.forum-post .forum-post-wrapper {
  border: 1px solid #C1C1C1;
}
div.forum-post .forum-post-wrapper .forum-post-panel-sub {
  float: none;
  width: 100%;
}
div.forum-post .forum-post-wrapper .forum-post-panel-sub .author-pane {
  margin: 0;
  padding: 8px;
}
div.forum-post .forum-post-wrapper .forum-post-panel-main {
  border-left: none;
  min-height: 0;
  margin-left: 0;
}
div.forum-post .forum-post-wrapper .forum-post-panel-main .forum-post-title,
div.forum-post .forum-post-wrapper .forum-post-panel-main .post-title {
  padding: 8px;
  font-weight: bold;
  border-bottom: 1px solid #c1c1c1;
}
div.forum-post .forum-post-wrapper .forum-post-panel-main .forum-post-content,
div.forum-post .forum-post-wrapper .forum-post-panel-main .post-edited {
  padding: 16px 12.030075188px;
}
div.forum-post .forum-post-wrapper .forum-post-panel-main .post-edited {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3;
  margin: 0;
  padding: 16px 12.030075188px;
}
div.forum-post .forum-post-footer {
  border-top: 0;
}
.page-comment-reply div.forum-post .forum-post-footer {
  display: none;
}
div.forum-post .forum-post-footer .forum-jump-links {
  display: none;
}
div.forum-post .forum-post-footer .forum-post-links {
  float: none;
}

.forum-post-content ul,
.forum-post-content ol {
  margin-bottom: 16px;
}
.forum-post-content ul li,
.forum-post-content ol li {
  background: url("../../images/list-style-image-micro.png") no-repeat 0 8px;
  margin-bottom: 0.444444444em;
  margin-left: 0.444444444em;
  list-style: none;
  padding-left: 0.944444444em;
}
.forum-post-content ol li {
  list-style: decimal;
}
.forum-post-content blockquote {
  background: #fff;
  border-left: 3px solid #ccc;
  box-shadow: 0 0 0 1px #ccc;
  color: #444;
  display: inline-block;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3;
  margin: 16px 2px;
  max-width: 600px;
  padding: 12px 24px;
}
.forum-post-content blockquote p:last-child {
  margin-bottom: 0;
}

.forum-post__attached-content {
  margin-top: 20px;
}

.forum-post__image-thumb {
  box-sizing: border-box;
  display: block;
  float: left;
  padding: 0px 10px 0px 0px;
  width: 50%;
}

.comment-form .field-type-text-long .filter-wrapper,
.node-forum-form .field-type-text-with-summary .filter-wrapper {
  display: none;
}

.node-forum-form .field-type-text-with-summary .ckeditor_links,
.node-ads-form .field-type-text-with-summary .ckeditor_links,
.comment-form .field-type-text-long .ckeditor_links {
  display: none !important;
}

.comment-form .field-type-image,
.node-forum-form .field-type-image,
.comment-form .field-type-file,
.node-forum-form .field-type-file {
  border: 1px solid #c1c1c1;
  margin: 16px 0;
  padding: 16px;
}
.comment-form .field-type-image label,
.node-forum-form .field-type-image label,
.comment-form .field-type-file label,
.node-forum-form .field-type-file label {
  display: none;
}
.comment-form .field-type-image .tabledrag-toggle-weight-wrapper,
.node-forum-form .field-type-image .tabledrag-toggle-weight-wrapper,
.comment-form .field-type-file .tabledrag-toggle-weight-wrapper,
.node-forum-form .field-type-file .tabledrag-toggle-weight-wrapper {
  display: none;
}
.comment-form .field-type-image .tabledrag-hide,
.node-forum-form .field-type-image .tabledrag-hide,
.comment-form .field-type-file .tabledrag-hide,
.node-forum-form .field-type-file .tabledrag-hide {
  display: none;
}
.comment-form .field-type-image tr .form-type-select,
.node-forum-form .field-type-image tr .form-type-select,
.comment-form .field-type-file tr .form-type-select,
.node-forum-form .field-type-file tr .form-type-select {
  margin-bottom: 0;
}
.comment-form .field-type-image .even,
.node-forum-form .field-type-image .even,
.comment-form .field-type-file .even,
.node-forum-form .field-type-file .even {
  background: #fafafa;
}
.comment-form .field-type-image .odd,
.node-forum-form .field-type-image .odd,
.comment-form .field-type-file .odd,
.node-forum-form .field-type-file .odd {
  background: #fff;
}
.comment-form .field-type-image th,
.node-forum-form .field-type-image th,
.comment-form .field-type-file th,
.node-forum-form .field-type-file th {
  background: #eee;
  border-bottom: 1px solid #e1e1e1;
  padding: 7px;
  text-align: left;
}
.comment-form .field-type-image td,
.node-forum-form .field-type-image td,
.comment-form .field-type-file td,
.node-forum-form .field-type-file td {
  padding: 5px;
  vertical-align: middle;
}
.comment-form .field-type-image td .image-preview,
.node-forum-form .field-type-image td .image-preview,
.comment-form .field-type-file td .image-preview,
.node-forum-form .field-type-file td .image-preview {
  display: none;
}

.node-forum-form .field-type-file,
.comment-form .field-type-file {
  margin-top: 0;
}

.comment-form .form-item-notify {
  margin: 5px 0;
}
.comment-form .form-item-notify .form-checkbox,
.comment-form .form-item-notify .option {
  display: inline;
}
.comment-form .form-item-notify .option {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.3;
  font-weight: 500;
}
.comment-form #edit-notify-type .form-item-notify-type {
  display: block;
  margin-bottom: 0;
}
.comment-form #edit-notify-type .form-item-notify-type label {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.3;
}

.googtube-embed-wrapper {
  margin-bottom: 10px;
  max-height: 250px;
  max-width: 400px;
}

.googtube-embed {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%; /* 16/9 ratio */
  padding-top: 30px; /* IE6 workaround*/
  position: relative;
}

.googtube-embed iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform-style: preserve-3d; /* iOS workaround */
  width: 100%;
}

.attached-images {
  margin-bottom: 10px;
}

.forum__attached-files-header {
  margin-bottom: 4px;
}

.forum-table-topics td.views-field-topic-icon {
  padding-right: 0;
}